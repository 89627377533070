//
// authentication.scss
//

// authentication

.authentication-bg {
  // background-color: $auth-bg;
  background: var(--cover-photo);
  background-size: cover;
  background-position: center;
  max-height: 100vh;
  .auth-logo {
    .logo {
      margin: 0px auto;
      width: 78%;
      // width: 100%;
      height: auto;
    }
  };
}

// signin card title

.signin-other-title {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    background-color: var(--#{$prefix}border-color);
    top: 10px;
  }

  .title {
    display: inline-block;
    position: relative;
    z-index: 9;
    background-color: $card-bg;
    padding: 2px 16px;
  }
}

// auth carousel

.auth-carousel {
  &.owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          background-color: $card-bg;
        }
        &.active {
          span {
            background-color: $primary;
          }
        }
      }
    }
  }
}

.form-check-input[type=checkbox] {
  border-color: #1A71B5;
  border-radius: 0.25em;
  font-size: 14px;
}