.checkbox5 {
  align-items: center;
  padding-top: 5px;
}
.checkbox5 input {
  margin: 0px;
}
.checkbox5 label {
  padding: 7px;
}

#QuestionsID th {
  text-align: center;
}
#FeedbackformId {

  td{
    vertical-align: middle;
    padding: 0.5rem;
  }

  td:nth-child(3) {
    text-align: center;
  }

  td:nth-child(7){
    max-width: 110px !important;
  }
}

.feedback-delete {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

input:disabled {
  background-color: #f2f2f2 !important;
}
