.phone_frame {
  --cream-white-col: #f5f6fa;
  --light-gray-col: #b9bbc2;
  --light-white-col: #ebe7fb;

  --sender-col: #dcf8c6;
  --sender-text-col: #343747;
  --receiver-col: #e8e9eb;
  --receiver-text-col: #343747;

  --message-time-col: #555;
}

.phone_frame {
  min-width: 330px;
  max-width: 330px;
  height: 630px;
  border-radius: 20px;
  background: #f7f7f7;
  border: 2px solid #333;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.7);
  overflow: hidden;
  position: sticky;
  top: 4.8rem;

  &::before {
    content: "";
    position: absolute;
    top: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 13px;
    height: 13px;
    background: rgba(31, 26, 26, 0.7);
    border-radius: 50px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 102px;
    height: 2.5px;
    background: rgba(0, 0, 0, 0.466);
    border-radius: 50px;
  }

  .meta {
    background-color: #fff;
    display: flex;
    padding: 13px;
    padding-bottom: 5px;

    gap: 10px;
    justify-content: start;
    align-items: center;

    .img {
      height: 2rem;
      width: 2rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h1 {
      font-weight: bold;
      font-size: 0.9rem;
    }
  }

  .message-body {
    background-color: #f7f7f7;
    background-size: contain;
    background-position: center;
    padding: 19px 5px;
    width: 100%;
    height: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 100px;

    .file-content {
      width: 100%;
      height: 100%;
      overflow: hidden;

      img,
      object,
      video {
        width: 100%;
        height: 100%;
        max-height: 200px;
      }

      img {
        object-fit: cover;
      }

      audio {
        background-color: var(--receiver-col);
        color: var(--sender-text-col);
        border-radius: 10px;
      }

      /* Styling the controls (works in some browsers) */
      audio::-webkit-media-controls-panel {
        background-color: var(--receiver-col);
        color: var(--sender-text-col);
        border-radius: 10px;
      }

      audio::-webkit-media-controls-play-button {
        background-color: #ddd;
        border-radius: 50%;
      }

      audio::-webkit-media-controls-timeline {
        background-color: #ddd;
        border-radius: 15px;
        margin-left: 5px;
        margin-left: 5px;
      }

      audio::-webkit-media-controls-current-time-display,
      audio::-webkit-media-controls-time-remaining-display {
        color: var(--sender-text-col);
      }
    }

    .chat_item {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      margin-bottom: 15px;
      transition: all 0.3s ease;
      animation-direction: normal;
      animation-fill-mode: both;
      -webkit-animation-delay: 0.2s;
      animation-iteration-count: 1;
      animation-duration: 0.2s;
      animation-delay: 0.3s;

      flex-direction: row-reverse;
      -webkit-transform-origin: left;
      transform-origin: left;
    }

    .chat_item_content {
      position: relative;
      background-color: var(--sender-col);
      color: var(--sender-text-col);
      padding: 5px 10px;
      border-radius: 10px;

      margin-left: 10px;

      .chat_header {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 15px;
      }

      .chat_msg {
        font-size: 14px;
      }
      .chat_footer {
        margin-top: 5px;
        font-size: 13px;
        color: #9e9e9e;
      }

      .chat_buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        padding: 5px;

        a {
          width: 100%;
        }

        button {
          font-size: 14px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;

          img {
            width: 15px;
            height: 15px;
          }

          border-radius: 5px;
          padding: 10px 15px;
          outline: none;
          border: 1px solid #ebe7fb;
          width: 100%;
          background-color: white;
          color: #009de2;
        }
      }

      .chat_msg,
      .chat_header {
        width: 100%;
        height: 100%;
        overflow-wrap: break-word;
      }
    }

    .chat_item_content .chat_meta {
      justify-content: space-between;
      display: flex;
      align-items: end;
      justify-content: end;
      margin-top: 5px;
    }

    .chat_item_content .chat_meta span {
      font-size: 12px;
      color: var(--message-time-col);
      -webkit-user-select: none;
      user-select: none;
    }
  }

  .chat_item {
    .chat_item_content {
      background-color: var(--receiver-col);
      color: var(--receiver-text-col);
      max-width: 95%;
    }
    .chat_item_content::before {
      content: "";
      position: absolute;
      top: 0;
      left: -12px;
      width: 20px;
      height: 20px;
      background: linear-gradient(
        225deg,
        var(--receiver-col) 0%,
        var(--receiver-col) 50%,
        transparent 50%,
        transparent
      );
    }
  }

  .chat-content-footer {
    position: absolute;
    bottom: 5px;
    left: 5px;
    right: 5px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid var(--light-white-col);
    border-radius: 8px;

    input {
      flex-grow: 1;
      padding: 0 9px;
      background-color: transparent;
      border: none;
      outline: none;
    }

    input:disabled {
      background-color: transparent !important;
    }

    button {
      width: 30px;
      height: 30px;
      background-color: #ecefff;
      border: none;
      box-shadow: none;
      outline: none;
      cursor: pointer;
      font-size: 15px;
      color: #4665ff;
      padding: 0;
      border-radius: 5px;
      line-height: 30px;
      transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
    }

    .addFiles {
      cursor: pointer;
    }

    #sendMsgBtn {
      background-color: #3b5bfe;
      color: #fff;
    }
  }
}
