.text-editor {
  .se-tooltip-inner {
    font-family: Arial;
  }

  .sun-editor {
    box-sizing: border-box;
  }

  .se-btn-tray {
    background-color: #fff;
  }
  .se-btn {
    color: var(--text-normal);
  }

  .se-btn.active {
    color: var(--text-normal-active);
  }

  [aria-label="Undo"],
  [aria-label="Redo"] {
    color: var(--text-normal-active);
  }

  [aria-label="Undo"][disabled="true"],
  [aria-label="Redo"][disabled="true"] {
    color: var(--text-normal);
  }

  .picmo__picker .picmo__preview {
    display: none !important;
  }
}

