
.mytable1{
    background-color: white !important;
}
.mytable1 thead th{
    text-align: center;
  background-color: white;
    vertical-align: middle;
    min-width: 125px;

}

.mytable1 tbody td,.mytable1 tbody th{
    background-color: white;
}
.mytable1 td{
    text-align: center;
    
}

 .mytable1 thead th:nth-child(1){
   background-color:#006bae !important;
   min-width: 16rem !important;
   color: white;
   

}

.mytable1 thead th:nth-child(1) p:nth-child(1){
    margin-bottom: 0px;
    text-align: left;
}
.mytable1 thead th:nth-child(1) p:nth-child(2){
    margin-bottom: 0px;
    text-align: right;
}

// .mytable1 tbody tr th{
//     background-color:whitesmoke;
    
// }

.gmbtable::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

.gmbtable::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.441); 
    border-radius: 10px;
  } 

  .gmbtable::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.258); 
    border-radius: 10px;
  }

  th.sticky-col {
    position: sticky;
    left: 0;
    text-align: left;
    
  }
