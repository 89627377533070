.chat-box-container {
  --cream-white-col: #f5f6fa;
  --light-gray-col: #b9bbc2;
  --light-white-col: #ebe7fb;

  --sender-col: #dcf8c6;
  --sender-text-col: #343747;
  --receiver-col: #e8e9eb;
  --receiver-text-col: #343747;

  --message-time-col: #555;
  --green-col: #45a569;
  --lead-width: 270px;
}

.chat-box-container {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  width: calc(100% - var(--lead-width));
  display: flex;
  height: 100%;

  .chat-box-user-list {
    border-right: 1px solid #ebe7fb;
    padding: 0 10px 0 0px;
    width: 350px;
    overflow: hidden;

    .chat-box-search {
      background-color: white;
      border: 1px solid var(--light-gray-col);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 39px;

      input {
        font-size: 13px;
        padding: 10px;
        padding-left: 15px;
        width: 100%;
        height: 100%;
        background-color: initial;
        border: none;
        outline: none;
      }

      .search-btn {
        cursor: pointer;
        font-size: 20px;
        background-color: initial;
        border: none;
        outline: none;
        width: 40px;
      }
    }

    .chat-user-list {
      scrollbar-width: none;
      -ms-overflow-style: none;
      margin-top: 0px;
      padding: 5px 0px 50px 0px;
      overflow: auto;
      height: 100%;
      // border-right: 1px solid #ebe7fb;

      .chat-user {
        position: relative;

        padding-bottom: 10px;
        cursor: pointer;
        padding: 10px 10px 10px 20px;
        display: flex;
      }

      .chat-user.bottom_border {
        border-bottom: 1px solid #ebe7fb;
      }

      .new-message-count {
        right: 10px;
        top: 10px;
        position: absolute;
        background-color: var(--green-col);
        padding: 0px 6px;
        border-radius: 10px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--cream-white-col);
        font-weight: 600;
      }

      .chat-user.active {
        background-color: #f2f7ff;
        cursor: pointer;
      }

      .userMeta p {
        margin: 0;
        padding: 0;
        color: #000;
        font-weight: 600;
        font-size: 14px;
        white-space: nowrap;
        width: 190px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .userMeta span {
        margin: 0;
        padding: 0;
        color: var(--message-time-col);
        font-weight: 400;
        font-size: 12px;
        display: block;
      }
    }
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    position: relative;
  }

  .platform-icon {
    position: absolute;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: -7px;
    right: -12px;
  }

  .avatar_img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 100%;

    .img-letter {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #a3a2a2bf;
      color: #f7f7f7;
      font-size: 15px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .avatar_img img {
    width: 100%;
  }
}

// =====> main chat box

.chat-box-container {
  .main_chat_content {
    position: relative;
    flex-grow: 1;
    width: 100%;
    width: calc(100% - 350%);
    height: 100%;

    .content_header {
      padding: 0px 20px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 9px;
      border-bottom: 1px solid var(--light-white-col);

      .current-chatting-user {
        display: flex;
        align-items: center;
        font-size: 15px;

        .mobile-back {
          display: none;
          border: 1px solid var(--light-white-col);
          border-radius: 5px;
          margin-right: 10px;
          overflow: hidden;
          height: 30px;
          width: 30px;
          align-items: center;
          justify-content: center;

          i {
            font-size: 20px;
            color: var(--receiver-text-col);
          }
        }

        @media screen and (max-width: 1300px) {
          .mobile-back {
            display: flex;
          }
        }
      }
    }
    .chat-content {
      background-color: #f7f7f7;
      background-size: contain;
      background-position: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      z-index: 1;
      padding: 50px 10px;

      ::-webkit-scrollbar {
        width: 0px;
      }

      .chat-content-body {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 20px 0px;
      }

      .chat-content-footer {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid var(--light-white-col);
        border-radius: 8px;
        position: absolute;
        left: 5px;
        right: 5px;
        bottom: 5px;
        z-index: 2;

        .send-box {
          display: flex;
          justify-content: space-between;
        }

        .variable-container {
          height: 290px;
          overflow-y: auto;
          border-bottom: 1px solid var(--light-white-col);
          margin-bottom: 10px;
        }

        .template-select {
          flex-grow: 1;
          padding: 0 15px;
          background-color: transparent;
          border: none;
          outline: none;

          .css-13cymwt-control,
          .css-t3ipsp-control {
            border: none;
            outline: none;

            &:hover {
              border: none !important;
              outline: none !important;
            }
          }

          .css-t3ipsp-control:hover {
            border: none;
          }
        }

        .footer-chat-input {
          flex-grow: 1;
          padding: 0 15px;
          background-color: transparent;
          border: none;
          outline: none;
        }

        button {
          width: 36px;
          height: 36px;
          background-color: #ecefff;
          border: none;
          box-shadow: none;
          outline: none;
          cursor: pointer;
          font-size: 16px;
          color: #4665ff;
          padding: 0;
          border-radius: 5px;
          line-height: 36px;
          transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
        }

        .addFiles {
          cursor: pointer;
        }

        #sendMsgBtn {
          background-color: #3b5bfe;
          color: #fff;
        }
      }
    }
  }
}
.whatsapp-split.noChatId {
  .chat-box-container {
    width: 100%;
  }
}

.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.chat-content-body {
  // 45a569

  .chat_item {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 15px;
    transition: all 0.3s ease;
    animation-direction: normal;
    animation-fill-mode: both;
    -webkit-animation-delay: 0.2s;
    animation-iteration-count: 1;
    animation-duration: 0.2s;
    animation-delay: 0.3s;

    .chat_item_content {
      position: relative;
      background-color: var(--sender-col);
      color: var(--sender-text-col);
      padding: 5px 10px;
      border-radius: 5px;
      min-width: 60px;
      // max-width: 50%;

      .chat_header {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 15px;
      }

      .chat_msg {
        font-size: 14px;
      }
      .chat_footer {
        margin-top: 5px;
        font-size: 13px;
        color: #9e9e9e;
      }

      .chat_buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        padding: 5px;

        a {
          width: 100%;
        }

        button {
          font-size: 14px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;

          img {
            width: 15px;
            height: 15px;
          }

          border-radius: 5px;
          padding: 10px 15px;
          outline: none;
          border: 1px solid #ebe7fb;
          width: 100%;
          background-color: white;
          color: #009de2;
        }
      }

      .chat_msg,
      .chat_header {
        width: 100%;
        height: 100%;
        overflow-wrap: break-word;
      }

      .read-check {
        position: relative;
        width: 30px;
        height: 17px;
        font-size: 16px;
        color: #9da9ae;

        i {
          position: absolute;
          top: 0;
          right: 3px;
        }

        .double-check {
          right: 0px;
        }
      }

      .read-check.active {
        color: #48abd5;
      }

      .read-check.failed {
        color: #d55d48;
      }
    }

    .chat_item_content .chat_meta {
      justify-content: space-between;
      display: flex;
      align-items: end;
      justify-content: end;
      margin-top: 5px;
    }

    .chat_item_content .chat_meta span {
      font-size: 12px;
      color: var(--message-time-col);
      -webkit-user-select: none;
      user-select: none;
    }

    // .avatar {
    //   margin-right: 0px;
    //   margin-left: 20px;
    //   background: #fff;
    //   padding: 1px;
    // }
  }

  .chat_item.me {
    .chat_item_content {
      margin-right: 10px;
    }
  }
  .chat_item.other {
    .chat_item_content {
      margin-left: 10px;
    }
  }

  .chat_item.other {
    flex-direction: row-reverse;
    -webkit-transform-origin: left;
    transform-origin: left;

    // .avatar {
    //   margin-right: 20px;
    //   margin-left: 0px;
    // }

    .chat_item_content {
      background-color: var(--receiver-col);
      color: var(--receiver-text-col);
      max-width: 50%;
    }
  }

  .chat_item.me {
    .chat_item_content::before {
      content: "";
      position: absolute;
      right: -12px;
      top: 0;
      width: 20px;
      height: 20px;
      background: linear-gradient(
        135deg,
        var(--sender-col) 0%,
        var(--sender-col) 50%,
        transparent 50%,
        transparent
      );
    }
  }
  .chat_item.other {
    .chat_item_content::before {
      content: "";
      position: absolute;
      top: 0;
      left: -12px;
      width: 20px;
      height: 20px;
      background: linear-gradient(
        225deg,
        var(--receiver-col) 0%,
        var(--receiver-col) 50%,
        transparent 50%,
        transparent
      );
    }
  }

  .chat_item.same-line {
    .chat_item_content::before {
      display: none !important;
    }

    // .avatar {
    //   display: none;
    // }
  }
}

.chat-box-container {
  .settings {
    display: none;
  }

  @media screen and (max-width: 1300px) {
    width: calc(100% - var(--lead-width));
    .chat-box-user-list {
      display: none;
    }
  }

  @media screen and (max-width: 750px) {
    .settings {
      display: block;
    }
    .whatsapp-lead-container {
      display: none;
    }
  }
}

.whatsapp-lead-container {
  .card {
    border-radius: 5px;
  }
}

.whatsapp-split {
  @media screen and (max-width: 750px) {
    .chat-box-container {
      width: 100%;
      display: flex;
    }
    .whatsapp-lead-container {
      display: none;
    }
  }
}

.whatsapp-split.isLead {
  @media screen and (max-width: 750px) {
    .chat-box-container {
      display: none;
    }
    .whatsapp-lead-container {
      display: block;
      width: 100%;
    }
  }
}

// .chat-box-container.isMobile {
//   .chat-box-user-list {
//     border: none;
//   }
// }

.chat-box-container.isMobile {
  @media screen and (max-width: 1300px) {
    width: 100%;
    .no-conversation {
      display: none;
    }

    .chat-box-user-list {
      display: block;
      width: 100%;
    }

    .chat-box-user-list {
      border: none;
    }

    .main_chat_content {
      display: none;
    }
  }
}

.whatsapp-split {
  height: 70vh;
  display: flex;
}

// Whatsapp dropdown

.send-box {
  .whatsapp-dropdown {
    position: relative;
    filter: url(#goo);
  }

  .dropdown__face,
  .dropdown__items,
  .dropdown-file-upload {
    background-color: #fff;
    padding: 10px;
    border-radius: 25px;
  }
  .dropdown__face {
    display: block;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: #ecefff;
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    color: #4665ff;
    padding: 0;
    border-radius: 5px;
    line-height: 36px;
    transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
  }

  .dropdown-file-upload {
    width: 300px !important;
    height: 280px !important;
  }

  .dropdown__items,
  .dropdown-file-upload {
    margin: 0;
    position: absolute;
    left: 0;
    top: 50%;
    width: 260px;
    list-style: none;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.93, 0.88, 0.1, 0.8);

    .li-btn {
      font-size: 25px !important;
      width: 40px !important;
      height: 40px !important;
      background: #ecefff;
      color: #4665ff;
      border-radius: 10px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .dropdown__items::before,
  .dropdown-file-upload::before {
    content: "";
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 3%;
    height: 15px;
    width: 20px;
  }
  .whatsapp-dropdown input {
    display: none;
  }
  .whatsapp-dropdown input:checked ~ .dropdown__items.active,
  .whatsapp-dropdown input:checked ~ .dropdown-file-upload.active {
    // top: calc(100% + 25px);
    top: -80px;
    visibility: visible;
    opacity: 1;
  }

  .whatsapp-dropdown input:checked ~ .dropdown-file-upload.active {
    top: -300px;
  }

  * {
    box-sizing: border-box;
  }

  svg {
    display: none;
  }
}

.dropdown-file-upload {
  padding: 20px;
  object,
  img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    gap: 10px;

    h1 {
      text-align: center;
      font-size: 0.9rem;
    }

    i {
      font-size: 30px;
    }
  }

  input {
    display: none;
  }
}

.info-message {
  cursor: pointer;
  position: relative;
  text-align: center;
  background: #d6f3e9;
  border: 1px solid var(--light-white-col);
  padding: 5px 17px;
  border-radius: 3px;
  font-weight: bold;
  color: #2b2e41;
  margin: auto;
}

.reaction {
  position: absolute;
  background: #9b9696;
  border-radius: 7px;
  height: 20px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  right: 0;
}

.chat_item_content.reaction-msg {
  margin-bottom: 10px;
}

.chat_item.other {
  .reaction {
    left: 0;
  }
}

.chat_item_content {
  .file-content {
    width: 100%;
    height: 100%;
    overflow: hidden;

    img,
    object,
    video {
      width: 100%;
      height: 100%;
      max-height: 300px;
    }

    img {
      object-fit: cover;
    }

    audio {
      background-color: var(--receiver-col);
      color: var(--sender-text-col);
      border-radius: 10px;
    }

    /* Styling the controls (works in some browsers) */
    audio::-webkit-media-controls-panel {
      background-color: var(--receiver-col);
      color: var(--sender-text-col);
      border-radius: 10px;
    }

    audio::-webkit-media-controls-play-button {
      background-color: #ddd;
      border-radius: 50%;
    }

    audio::-webkit-media-controls-timeline {
      background-color: #ddd;
      border-radius: 15px;
      margin-left: 5px;
      margin-left: 5px;
    }

    audio::-webkit-media-controls-current-time-display,
    audio::-webkit-media-controls-time-remaining-display {
      color: var(--sender-text-col);
    }
  }
}

.chat_item_content.audio {
  max-width: 100% !important;
}

.chat_item {
  .replied {
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px solid var(--light-gray-col);
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 4px;

    h2 {
      font-size: 13px;
      font-weight: bold;
    }

    p {
      margin: 0;
      padding: 0;
      white-space: nowrap;
      width: 190px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.no-conversation {
  flex: 2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;

  h1 {
    font-size: 16px;
    font-weight: bold;
  }
}

.inbox-welcome-screen {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  h1 {
    font-size: 2.5rem;
  }

  p {
    font-size: 0.9rem;
  }

  ul {
    display: flex;
    gap: 30px;
    list-style: none;

    li {
      padding: 19px;
      background-color: #f0f0f0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 9px;
      width: 300px;

      button {
        border: 1px solid var(--light-gray-col);
        background: #fff;
        padding: 6px 23px;
        font-weight: 500;
      }
    }
  }
}

.inbox-loader {
  background-color: white;
  border-radius: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  .spinner-border {
    width: 20px;
    height: 20px;
  }

  h5 {
    font-size: 13px;
    font-weight: bold;
    margin: 0;
  }
}

.inbox-loader.active {
  width: 100%;
  height: 100%;
}
