table {
  th {
    text-align: center;
  }
  td:nth-child(1) {
    text-align: center;
  }

  td:last-child {
    text-align: center;
  }
}

#branchTableId {
  td:nth-child(1) {
    text-align: center;
  }
}
