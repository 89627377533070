.checkbox5{
  align-items: center;
  padding-top: 5px;

}
.checkbox5 input{
  margin: 0px;
}
.checkbox5 label{

  padding: 7px;


}

#QuestionsID th{
  text-align: center;
}
#QuestionsID td:nth-child(1){
  text-align: center;
}

#AssignFeedbackId{
  td:nth-child(1),
  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(8), 
  td:nth-child(9){
    text-align: center;
  }
}