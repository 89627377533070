#new-template {
  --text-normal: #9798a1;
  --text-normal-active: #4d4e57;

  .preview {
    background-color: #f5f6fa;
    width: 100%;
    height: 100%;
  }

  .css-1nmdiq5-menu {
    z-index: 9;
  }

  .button-choice {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    padding: 10px 0;
  }

  .action-btn {
    background-color: #f4faf6;
    border: 2px solid #6d9981;
    border-radius: 5px;
    color: #6d9981;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 35px;
    justify-content: center;
    gap: 2px;
    margin-left: 3px;
  }
}

.custom-url-enter {
  display: flex;
  width: 100%;
  padding: 0.47rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343747;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  select {
    border: none;
    height: 100%;
    outline: none;
  }

  input {
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
  }
}

.attribute-container {
  button {
    color: #45a569;
    background: transparent;
    border: none;
    outline: none;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
}

.template-creation-model {
  display: flex;
  gap: 10px;
  justify-content: start;
  align-items: start;
  margin-bottom: 10px;

  .phone_frame {
    // position: fixed;
    min-width: 270px;
    max-width: 270px;
    height: 472px;
  }

  @media screen and (max-width: 700px) {
    .phone_frame.one {
      display: none;
    }
  }
}
