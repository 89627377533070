.nameOnlyTable {
  th {
    text-align: center;
    text-wrap: nowrap;
    vertical-align: middle;
  }

  td {
    padding: 0.5rem;
    text-align: start;
    vertical-align: middle;
    text-wrap: nowrap;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 560px) {
      flex-direction: column;
      justify-items: start;
      align-items: start;
    }
  }

  .extra-options {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;

    button {
      background-color: transparent;
      border: none;
      color: var(--black-col);
      font-weight: bold;
      border-radius: 0;

      &:active,
      &:hover {
        background-color: #dad8d8;
      }
    }
  }
}
