.day-cell {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.date {
  margin-top: 5px; /* Adjust this value as needed to fit your design */
}

.icon {
  // display: absolute;
  // bottom: 0px;
  margin-bottom: 25px; /* Adjust this value as needed to fit your design */
}