.dashboard-count {
  margin: 0 !important;
  .card-counter {
    position: relative;
    margin: 5px;
    padding: 20px 23px;
    background-color: #f5f6fa;
    border: 1px solid #eeeeee;
    height: 100px;
    border-radius: 5px;

    .img {
      width: 35px;
      height: 35px;
      padding: 5px;
      background-color: #fff;
      border-radius: 5px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .count-numbers {
      position: absolute;
      right: 35px;
      top: 20px;
      font-size: 32px;
      display: block;
      font-weight: bold;
    }

    .count-name {
      position: absolute;
      right: 35px;
      top: 65px;
      font-style: italic;
      text-transform: capitalize;
      opacity: 0.5;
      display: block;
      font-size: 13px;
    }
  }

  @media screen and (max-width: 1200px) {
    > div {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
  }

  @media screen and (max-width: 970px) {
    > div {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media screen and (max-width: 440px) {
    > div {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.whatsapp-container-2 {
  @media screen and (max-width: 770px) {
    .card-header {
      flex-direction: column;
      justify-content: start;
      align-items: start;
    }
  }
}
