

#feedbackLogTableId {
    th {
      text-align: center;
    }
    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(7) {
      text-align: center;
    }
  
    td:last-child {
      text-align: center;
    }
  }