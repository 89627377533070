.whatsapp-tableModule {
  th {
    text-align: center;
    text-wrap: nowrap;
    vertical-align: middle;
  }

  td {
    padding: 0.5rem;
    text-align: start;
    vertical-align: middle;
    text-wrap: nowrap;
  }
}

.label-select-disabled {
  // .css-1dyz3mf {
  //   flex-wrap: nowrap;
  // }
  background-color: transparent;
  .css-16xfy0z-control {
    background-color: transparent !important;
    border: none;
  }

  .css-1p3m7a8-multiValue {
    padding: 0 2px;
  }

  .css-1wy0on6 {
    display: none;
  }

  .css-v7duua {
    display: none;
  }
}

.contact-list-model {
  display: flex;
  align-items: start;
  justify-content: center;
  .modal-content {
    min-width: 71vw;

    @media screen and (max-width) {
      min-width: 90vw;
    }
  }

  .modal-body {
    max-height: 600px;
    overflow-y: auto;
  }
}

.contact-list {
  --black-col: #383c40;
  --white-col: #e6e6e6;
  --divider-col: #bdb9b9;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 606px) {
      .download-btn span {
        display: none;
      }
    }

    @media screen and (max-width: 365px) {
      flex-direction: column;
      justify-items: start;
      align-items: start;
    }
  }
}
