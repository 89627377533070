.settings-btn{
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  cursor: pointer; 
  font-size: 14px;
}

.settings-btn:hover{
  color: rgb(42, 42, 224);
}