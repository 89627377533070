.content {
  padding: 2rem;
  @media screen and (max-width:1200px) {
    padding: 1rem;
  }
}

.title-p{
  font-size: 20px;
  color: #353535c4;
}

.count{
  font-size: 30px;
  color: #353535;
}

.bottom-text{
  font-size: 16px;
  color: #353535c4;
  margin-bottom: 0;
  // margin-top: 14px;
}
.bottom-text.success::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='14' viewBox='0 0 23 14' fill='none'%3E%3Cpath d='M16.143 0.708832L18.6674 3.23324L13.2879 8.61277L8.87847 4.20332L0.709961 12.3828L2.26429 13.9372L8.87847 7.323L13.2879 11.7325L20.2328 4.7986L22.7572 7.323V0.708832H16.143Z' fill='%2300E510'/%3E%3C/svg%3E");
  display: inline-block;
  width: 24px;
  background-repeat: no-repeat;
  height: 14px;
  background-position: center;
  object-fit: cover;
}

.bottom-text.fail::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='14' viewBox='0 0 23 14' fill='none'%3E%3Cpath d='M7.56595 13.9371L5.04154 11.4127L10.4211 6.03314L14.8305 10.4426L22.999 2.26306L21.4447 0.708727L14.8305 7.3229L10.4211 2.91345L3.47619 9.84731L0.951778 7.3229V13.9371H7.56595Z' fill='%23E51D28'/%3E%3C/svg%3E");
  display: inline-block;
  width: 24px;
  background-repeat: no-repeat;
  height: 14px;
  background-position: center;
  object-fit: cover;
}

.bottom-text.success span{
  color: #00E510;
  margin-left: 5px;
}
.bottom-text.fail span{
  color: #E51D28;
  margin-left: 5px;
}


