// Modal styles
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease-out;
  
  &.visible {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  &.closing {
    background-color: rgba(0, 0, 0, 0);
  }

  .modal-dialog {
    width: 100%;
    max-width: 900px;
    margin: 1.75rem auto;
  }

  .modal-content {
    transform: translateY(100vh);
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
                opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    
    &.slide-up {
      transform: translateY(0);
      opacity: 1;
    }
    
    &.slide-down {
      transform: translateY(100vh);
      opacity: 0;
    }

    .modal-header {
      background: linear-gradient(135deg, #0061f2 0%, #0044ab 100%);
      padding: 1.25rem 1.5rem;
      border: none;
      
      .modal-title {
        color: white;
        font-weight: 600;
        font-size: 1.25rem;
        margin: 0;
      }
    }

    .modal-body {
      padding: 1.5rem;
      background: white;

      .card {
        border: none;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
        margin-bottom: 1.5rem;

        h6 {
          color: #1e2022;
          font-weight: 600;
          font-size: 1rem;
          margin-bottom: 1.25rem;
          position: relative;
          padding-bottom: 0.75rem;
          
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 40px;
            height: 2px;
            background: #0061f2;
          }
        }
      }
    }

    .modal-footer {
      background: #f8f9fa;
      padding: 1rem 1.5rem;
      border-top: 1px solid #e7eaf0;

      .btn {
        padding: 0.5rem 1.5rem;
        font-weight: 500;
        transition: all 0.2s;
        
        &:hover {
          transform: translateY(-1px);
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

// Filter and Form styles
.filters-container {
  .form-label {
    margin-bottom: 0.25rem;
    color: #6c757d;
  }

  .btn-sm {
    padding: 0.350rem 0.5rem;
    font-size: 0.800rem;
  }
}

// Form validation styles
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.mandatory {
  color: red;
}

.select-error {
  .select__control {
    border-color: #dc3545 !important;
    &:hover {
      border-color: #dc3545 !important;
    }
  }
}

.nav-link {
  cursor: pointer !important;
}
