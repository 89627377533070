.whatsapp-lead-container {
  --cream-white-col: #f5f6fa;
  --light-gray-col: #b9bbc2;
  --light-white-col: #ebe7fb;

  --sender-col: #dcf8c6;
  --sender-text-col: #343747;
  --receiver-col: #e8e9eb;
  --receiver-text-col: #343747;
  --lead-width: 270px;
}

.whatsapp-lead-container {
  width: var(--lead-width);
  height: 100%;

  .card {
    width: 100%;
    height: 100%;
    margin-bottom: 0 !important;
  }

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
      justify-content: start;
      align-items: start;
    }

    .close-btn {
      display: none;
      font-size: 1rem;
      border: 1px solid var(--light-gray-col);
      border-radius: 4px;

      @media screen and (max-width: 750px) {
        display: block;
      }
    }
  }

  textarea {
    resize: none;
    height: 70px;
  }

  .card-body {
    overflow-y: auto;
    height: 65vh;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    position: relative;
  }

  .platform-icon {
    position: absolute;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: -7px;
    right: -12px;
  }

  .avatar_img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 100%;

    .img-letter {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #a3a2a2bf;
      color: #f7f7f7;
      font-size: 15px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .avatar_img img {
    width: 100%;
  }
}
