.top_social{
    font-size: 10px;
    padding: 2px 9px;
    border-radius: 16px;
    color: white;
}

.top_socialcolor1{
    background-color: #5b73e8;
}

.top_socialcolor2{
    background-color:#34c38f;
}
.top_socialcolor3{
    background-color: #f1b44c;
}
.top_socialcolor4{
    background-color: #50a5f1;
}

.campaign-dashboard td{
    text-align: center;

}
.campaign1stth p,.campaign2stth p{
    margin-bottom: 0px;

}


.campaign-dashboard .campaign1stth{
    background-color: #5b73e8!important;
    text-align: end;
    margin-bottom: 0;   
    color: #fff;
}
.campaign-dashboard .campaign2stth{
    background-color: #34c38f!important;
    text-align: end;
    margin-bottom: 0;   
    color: #fff;
}


.campaign-dashboard tbody .rowtd{
    border-top: 1px solid #5b73e8;
    background-color: #5b73e8 !important;
    font-weight: 600;
    // background: #5b73e8;
    // border: 1px solid #5b73e8;
    color: #fff;

}
.campaign-dashboard tbody .rowtd1{
    border-top: 1px solid #34c38f;
    background-color: #34c38f !important;
    font-weight: 600;
    // background: #5b73e8;
    // border: 1px solid #5b73e8;
    color: #fff;

}

.rowtd p{
    position: absolute;
    margin-bottom: 0;
    transform: rotate(269deg);
    top: 50%;
    left: 8px;
}

.rowtd1 p{
    position: absolute;
    margin-bottom: 0;
    transform: rotate(269deg);
    top: 50%;
    left: 8px;
}

.rowtd th{
    text-align: start;
}

.headfont_size{
    font-size: 12px !important;
}