.whatsapp-tableModule {
  th {
    text-align: center;
    text-wrap: nowrap;
    vertical-align: middle;
  }

  td {
    padding: 0.5rem;
    text-align: center;
    vertical-align: middle;
    text-wrap: nowrap;
  }
}

.label-select-disabled {
  // .css-1dyz3mf {
  //   flex-wrap: nowrap;
  // }
  background-color: transparent;
  .css-16xfy0z-control {
    background-color: transparent !important;
    border: none;
  }

  .css-1p3m7a8-multiValue {
    padding: 0 2px;
  }

  .css-1wy0on6 {
    display: none;
  }

  .css-v7duua {
    display: none;
  }
}

.whatsapp-container {
  --black-col: #383c40;
  --white-col: #e6e6e6;
  --divider-col: #bdb9b9;

  .action-btn {
    background-color: #f4faf6;
    border: 2px solid #6d9981;
    border-radius: 5px;
    color: #6d9981;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;

    .arrow-up-animation {
      rotate: 0deg;
      transition: .6s ease-out;
    }

    .arrow-down-animation {
      rotate: 180deg;
      transition: .6s ease-in;
    }
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  
    @media screen and (max-width: 606px) {
      .download-btn span {
        display: none;
      }
    }

    @media screen and (max-width: 365px) {
      flex-direction: column;
      justify-items: start;
      align-items: start;
    }
  }

  .extra-options {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;

    .input-output {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--white-col);
      border-radius: 20px;
      overflow: hidden;

      >button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;
        font-size: 13px;
        
        >i {
          font-weight: bold;
        }

        @media screen and (max-width: 455px) {
          span {
            display: none;
          }
        }
      }
    }

    .divider {
      width: 1px;
      height: 14px;
      background-color: var(--divider-col);
    }

    button {
      background-color: transparent;
      border: none;
      color: var(--black-col);
      font-weight: bold;
      border-radius: 0;

      &:active,
      &:hover {
        background-color: #dad8d8;
      }
    }
  }
}

.template-creation-model {
  display: flex;
  gap: 10px;

  @media screen and (max-width: 700px) {
    .phone_frame.one {
      display: none;
    }
  }
}